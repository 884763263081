































































.settingButton {
  // mobile and tablet only
  @media (max-width: 1023px) {
    min-width: 100%;
  }

}
