












































































































































































// modal fit mobile and tablet
@media (max-width: 1023px) {
  .modal-card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
  .modal-card-head {
    padding: 0.5rem;
  }
  .modal-card-body {
    padding: 0.5rem;
  }
  .modal-card-foot {
    padding: 0.5rem;
  }
  .modal-card-title {
    font-size: 1.5rem;
  }
  .modal-card-foot {
    justify-content: space-between;
  }
  .modal-card-foot .button {
    width: 48%;
  }
}
// button fit screen size
@media (max-width: 1023px) {
  .button {
    width: 100%;
  }
}

