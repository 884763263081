






















































































































.textile-product {
  border: solid;
  border-color: rgba(215, 228, 224, 0.678);
  border-width: 2px;
  min-width: 10rem;
  min-block-size: 100%;
  &:hover {
    border: solid;
    border-width: 2px;
  }
}
.textile-product-selected {
  border: solid;
  border-color: green;
  border-width: 4px;
  min-width: 10rem;
  min-block-size: 100%;
}

.addressesGrid{
  display: flex;
  .address-header{
    text-align: left;
    display: inline-block;
  }
  
  .address-text{
    display: inline-block;
    text-align: right;
    width: 100%;
  }
}

.standardAdress{
  width: 100%;
}

.adressButtons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    .button{
      width: 100%;
    }

}

.svg{
  width: 1.2rem;
}

