











































.selectDropdown {
  // mobile and tablet only
  @media (max-width: 1023px) {
    width: 100%;
  }
}
