











































































































.horizontal-line {
  height: 2rem;
}

// mobile and tablet swap
